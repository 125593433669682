<template>
    <Button label="Kembali" icon="pi pi-angle-left" class="p-button-link p-button-rounded p-button-success mb-2" @click="this.$router.go(-1)"/>
	<Toast />
	<ConfirmDialog></ConfirmDialog>
	<div class="grid fadein animation-duration-300">
		<div class="col-12">
			<div class="card">
				<div class="flex flex-wrap justify-content-between flex-row grid">
                    <div class="flex flex-column justify-content-start col">
                        <h5 class="mt-auto mb-auto">Daftar Donatur</h5>
						<small class="mt-auto mb-auto"><i>*pilih untuk melihat info lebih lengkap</i></small>
                    </div>
                    <div class="flex justify-content-end col">
                        <Button label="Donatur Baru" icon="pi pi-pencil" class="p-button-text" @click="openDonaturEntry"></Button>
                    </div>
                </div>
				<DataTable :value="listDonatur" :paginator="true" stripedRows class="p-datatable-gridlines" :rows="10" dataKey="id" :rowHover="true" 
							v-model:filters="filters1" filterDisplay="row" :loading="loading" :filters="filters1" responsiveLayout="scroll"
							:globalFilterFields="['nama', 'alamat', 'telepon', 'amil']"
							selectionMode="single" @rowSelect="DonaturSelect" v-model:selection="selectedDonatur">
					
					<template #header>
                        <div class="flex justify-content-between flex-column sm:flex-row">
                            <Button type="button" icon="pi pi-filter-slash" label="Reset Pencarian" class="p-button-outlined mb-2" @click="clearFilter1()"/>
                            <span class="p-input-icon-left mb-2">
                                <i class="pi pi-search" />
                                <InputText v-model="filters1['global'].value" placeholder="Cari" style="width: 100%"/>
                            </span>
                        </div>
                    </template>
                    <template #empty>
                        Daftar Donatur tidak ditemukan...
                    </template>
                    <template #loading>
                        Memuat daftar Donatur. Mohon menunggu...
                    </template>
                    <Column field="nama" header="Nama" style="min-width:12rem" :sortable="true">
                        <template #body="{data}">
                            {{data.nama}}
                        </template>
                        <template #filter="{filterModel,filterCallback}">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Cari nama" @input="filterCallback()"/>
                        </template>
                    </Column>
					<Column field="telepon" header="Telepon" style="min-width:12rem" :sortable="true">
                        <template #body="{data}">
                            {{data.telepon}}
                        </template>
                        <template #filter="{filterModel,filterCallback}">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Cari telepon" @input="filterCallback()"/>
                        </template>
                    </Column>
					<Column field="alamat" header="Alamat" style="min-width:12rem" :sortable="true">
                        <template #body="{data}">
                            {{data.alamat}}
                        </template>
                        <template #filter="{filterModel,filterCallback}">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Cari alamat" @input="filterCallback()"/>
                        </template>
                    </Column>
					<Column field="amil" header="Amil" style="min-width:12rem" :sortable="true"  v-if="(this.$store.state.user.role == 'Super Admin' || this.$store.state.user.role == 'Admin')">
                        <template #body="{data}">
                            {{data.nama_amil}}
                        </template>
                        <template #filter="{filterModel,filterCallback}">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Cari amil" @input="filterCallback()"/>
                        </template>
                    </Column>
				</DataTable>
			</div>
		</div>
	</div>
	<Dialog v-model:visible="donaturEntry" :style="{width: '600px'}" header="Tambah Donatur" :modal="true" class="p-fluid" :closable="false" :maximizable="true">
		<small>* wajib diisi</small>
        <form class="p-fluid">
            <fieldset :disabled="loadingRegister">
                <div class="form-group">
                    <div class="field">
                        <label for="nama">Nama Donatur *</label>
                        <InputText id="nama" v-model="nama" :class="{'p-invalid':submitted && !nama}" placeholder="Nama Lengkap" />
                        <small class="p-error" v-if="submitted && !nama">Nama harus diisi.</small>
                    </div>
					<div class="field">
                        <label for="jenis_kelamin" class="mb-3">Jenis Kelamin *</label>
                        <Dropdown id="jenis_kelamin" v-model="jenis_kelamin" :options="jenis_kelamins" optionLabel="label" optionValue="value" placeholder="Pilih jenis kelamin" required/>
                    </div>
					<div class="field">
						<label for="tgl_lahir" class="mb-3">Tanggal Lahir</label>
						<Calendar id="tgl_lahir" v-model="tgl_lahir"  dateFormat="yy-mm-dd" placeholder="yyyy-mm-dd" :manualInput="false" />
					</div>
					<div class="field">
                        <label for="no_hp">No Telp.</label>
                        <InputText id="no_hp" v-model="telepon" placeholder="08xx-xxxx-xxxx" />
                    </div>
                    <div class="field">
                        <label for="email">Email</label>
                        <InputText id="email" v-model="email" placeholder="contoh@email.com" />
                    </div>
					<div class="field">
                        <label for="pekerjaan">Pekerjaan/Perusahaan</label>
                        <InputText id="pekerjaan" v-model="pekerjaan" placeholder="Jabatan/Nama Perusahaan" />
                    </div>
					<div class="field">
                        <label for="alamat">Alamat Lengkap *</label>
                        <Textarea id="alamat" v-model="alamat" :class="{'p-invalid':submitted && !alamat}" placeholder="Alamat Lengkap (Jalan, Kecamatan, Kelurahan, Kota/Kabupaten, dll...)" :autoResize="true" rows="5" cols="30"  />
                        <small class="p-error" v-if="submitted && !alamat">Alamat harus diisi.</small>
                    </div>
                </div>
            </fieldset>
        </form>
        <template #footer>
            <Button label="Batalkan" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
            <Button label="Simpan" icon="pi pi-check" class="p-button-text" @click="newDonatur" :disabled="!nama || !alamat" />
        </template>
    </Dialog>
	<Toast></Toast>
</template>

<script>
	import {FilterMatchMode,FilterOperator} from 'primevue/api';

	import Donatur from '../api/Donatur'

	export default {
		name: 'Donatur',
        created() {
			this.donaturList = new Donatur();
        },
		data() {
			return {
				form: {},
				nama: '',
				email: '',
				tgl_lahir: '',
				jenis_kelamin: 'L',
				jenis_kelamins: [
                    {label: 'Laki-laki', value: 'L'},
                    {label: 'Perempuan', value: 'P'},
                ],
				telepon: '',
				pekerjaan: '',
				alamat: '',
				submitted: false,
				listDonatur: null,
				filters1: {
					'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
					'nama': {value: null, matchMode: FilterMatchMode.CONTAINS},
					'alamat': {value: null, matchMode: FilterMatchMode.CONTAINS},
					'telepon': {value: null, matchMode: FilterMatchMode.CONTAINS},
					'amil': {value: null, matchMode: FilterMatchMode.CONTAINS},
				},
				loading: false,
				checked: true,
				selectedDonatur: null,
				donaturEntry: false,
				loadingRegister: false,
			}
		},
		
		mounted() {
			this.loading = true;
			this.donaturList.getList()
                    .then(response => {
                        this.listDonatur = response.data
						this.loading = false;
                    })
                    .catch(error => {
                        console.log(error)
                    })
		},
		methods: {
			clearFilter1() {
				this.filters1 = {
					'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
					'nama': {value: null, matchMode: FilterMatchMode.CONTAINS},
					'email': {value: null, matchMode: FilterMatchMode.CONTAINS},
					'alamat': {value: null, matchMode: FilterMatchMode.CONTAINS},
					'pekerjaan': {value: null, matchMode: FilterMatchMode.CONTAINS},
					'jenis_kelamin': {value: null, matchMode: FilterMatchMode.EQUALS},
					'tgl_lahir': {value: null, matchMode: FilterMatchMode.CONTAINS},
					'telepon': {value: null, matchMode: FilterMatchMode.CONTAINS},
					'status': {value: null, matchMode: FilterMatchMode.EQUALS},
				}
			},
			DonaturSelect(event) {
				this.$router.push({name: 'DetailDonatur', params: { idDonatur: event.data.id} })
			},
			openDonaturEntry() {
                this.donaturEntry = true
            },
			hideDialog() {
                this.donaturEntry = false
            },
			newDonatur() {
				this.submitted = true;
				this.form = {
                    nama: this.nama,
                    alamat: this.alamat,
                    jenis_kelamin: this.jenis_kelamin,
                    tgl_lahir: this.tgl_lahir,
                    email: this.email,
                    telepon: this.telepon,
                    pekerjaan: this.pekerjaan,
					id_amil: this.$store.state.user.id,
					nama_amil: this.$store.state.user.nama
                }
				this.donaturList.store(this.form)
					.then(async response => {
						this.nama = ''
						this.alamat = ''
						this.jenis_kelamin = ''
						this.tgl_lahir = ''
						this.email = ''
						this.telepon = ''
						this.pekerjaan = ''
						this.id_amil = ''
						this.nama_amil = ''
						this.loadingRegister = false
						this.donaturEntry = false
						this.$toast.add({severity:'info', summary: 'Berhasil', detail: 'Berhasil menambahkan donatur', life: 3000});
						this.donaturList.getList()
							.then(response => {
								this.listDonatur = response.data
								this.loading = false;
							})
						})
					.catch(error => {
					})
			}
		}
	}
</script>

